import { Color, Text } from '@snowflake/core-ui';
import { AwsMarketplaceType, TrialType } from '@signup/shared';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSignupPageContext } from '../../pages/SignupContext';
import { isSnowparkLabSignup } from './isSnowparkLabSignup';

interface TitleProps {
  awsType?: AwsMarketplaceType;
  trialType?: TrialType;
  lab?: string;
}

export const Title = (props: TitleProps) => {
  const { locale } = useSignupPageContext();
  const { lab } = props;

  const renderText = () => {
    if (props.awsType === AwsMarketplaceType.ON_DEMAND) {
      return <FormattedMessage id="Start your subscription" />;
    } else if (
      props.trialType === TrialType.COMPETITION ||
      props.trialType === TrialType.DATA_SCIENCE_COMPETITION_JP ||
      props.trialType === TrialType.STARTUP
    ) {
      return (
        <>
          <FormattedMessage
            id="Start your <linebreak></linebreak> free trial"
            values={{
              linebreak: function LineBreak() {
                return <br />;
              },
            }}
          />
        </>
      );
    } else if (props.trialType === TrialType.STUDENT || props.trialType === TrialType.TRIAL_LEARN) {
      return (
        <>
          <FormattedMessage
            id="Start your<linebreak></linebreak>120-day free trial"
            values={{
              linebreak: function LineBreak() {
                return <br />;
              },
            }}
          />
        </>
      );
    } else if (isSnowparkLabSignup(lab)) {
      return <FormattedMessage id="Try Snowpark for python with free 30-day trial" />;
    } else {
      if (locale.value === 'en-US' || locale.value === 'ko') {
        return (
          <>
            <FormattedMessage
              id="Start your<linebreak></linebreak>30-day free trial"
              values={{
                linebreak: function LineBreak() {
                  return <br />;
                },
              }}
            />
          </>
        );
      } else {
        return <FormattedMessage id="Start your 30-day free trial" />;
      }
    }
  };

  return (
    <Text
      className="trial-title"
      allCaps={true}
      fontSize={50}
      color={Color.White}
      lineHeight="57px"
      weight="bold"
      marginBottom={24}
    >
      {renderText()}
    </Text>
  );
};
